.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background-color: black;
}

.card {
    background-color: #1f1f1f;
    border-radius: 12px;
    padding: 24px;
    width: 300px;
    text-align: center;
    color: white;
    margin: 20px auto;
}

.profile-image {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    margin: 0 auto 24px;
    overflow: hidden; 
    background-color: #333; 
}

.profile-image img {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    display: block; 
}

.name {
    font-size: 24px;
    margin: 0;
    font-weight: 600;
}

.location {
    color: #c4f82a;
    margin: 8px 0;
    font-size: 14px;
}

.description {
    color: #ffffff;
    margin: 24px 0;
    font-size: 14px;
}

.links-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.social-link {
    background-color: #333;
    color: white;
    padding: 12px;
    text-decoration: none;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.social-link:hover {
    background-color: #c4f82a;
    color: #1f1f1f;
}