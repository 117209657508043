.experiences {
  background: linear-gradient(to bottom, #000000, #111111, #222222);
  color: #ccd6f6;
  padding: 50px 20px;
}

.experiences-width {
  max-width: 900px;
  margin: 0 auto;
}

.experience, .education {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px; 
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.experience:hover, .education:hover {
  transform: translateY(-10px); 
  background-color: rgba(255, 255, 255, 0.1); 
}

.date {
  color: #8892b0;
  font-size: 14px;
  margin-bottom: 5px;
}

.details h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.details h3 span {
  font-weight: 400;
  color: #64ffda;
}

.details h3:hover {
  color: #64ffda; 
}

.details p {
  color: #a8b2d1;
  font-size: 16px;
  margin-bottom: 15px;
}

.links a {
  color: #64ffda;
  margin-right: 15px;
  font-size: 14px;
  text-decoration: none;
}

.links a:hover {
  color: #64ffda;
}

.technologies {
  margin-top: 10px;
}

.technologies .tech {
  display: inline-block;
  background: #112240;
  color: #64ffda;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-size: 14px;
}

.education-section,
.experience-section {
  margin-bottom: 50px;
}

.education {
  margin-bottom: 30px;
}

.education h3 {
  font-size: 18px;
  font-weight: 600;
}

.achievements {
  margin-top: 10px;
  padding-left: 20px;
}

.achievements li {
  color: #a8b2d1;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 5px; 
  border-radius: 5px; 
}

.links a {
  color: #FFFFFF;
  margin-right: 15px;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.links .link-icon {
  transform: rotate(-45deg);
  font-size: 16px;
  color: #FFFFFF;
  transition: color 0.3s ease;
}

.links a:hover {
  color: #64ffda;
}

.links a:hover .link-icon {
  color: #64ffda;
}

.details h3 .experience-icon {
  color: #FFFFFF;
}

.experience-icon {
  font-size: 15px;
  color: white;
  margin-left: 5px;
  margin-top: 10px;
  transition: transform 0.3s ease;
  display: inline-block; 
}

.experience:hover .experience-icon, .education:hover .experience-icon {
  transform: translateX(5px); 
}

.experience-link {
  text-decoration: none;
  color: inherit;
  display: block;
  transition: transform 0.2s ease-in-out;
}

.experience-link:hover {
  transform: translateX(8px);
}

.experience {
  cursor: pointer;
}

.links a {
  text-decoration: none;
  color: inherit;
  position: relative;
  z-index: 1;
}

@media (max-width: 820px) {
  .experiences {
    padding: 40px 20px; 
  }
}

/* For screens smaller than 480px (mobile phones) */
@media (max-width: 480px) {
  .experiences {
    padding: 20px 20px; 
  }
}