.project {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url('../images/project.jpg');
    background-attachment: fixed;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    padding: 30px 0px 60px 0px;
}

.project .project-width {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1240px;
}

.project .project-title {
    font-size: 28px;
    color: var(--text-clr);
    margin-bottom: 40px;
}

.project-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.project-card {
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 20px;
    max-width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    height: 100%;
    text-decoration: none;
}

.project-card:hover {
    transform: translateY(-10px);
    text-decoration: none;
}

.project-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.project-header h3 {
    font-size: 20px;
    color: white;
    margin: 0;
}

.project-header .project-icon {
    font-size: 15px;
    color: white;
    margin-left: 5px;
    transition: transform 0.3s ease;
    display: inline-block;
}

.project-card:hover .project-icon {
    transform: translateX(5px);
}

.project-body p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 20px;
}

.project-image img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.project-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}

.project-tags {
    display: flex;
    gap: 10px;
}

.project-tags span {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 5px 10px;
    color: white;
    font-size: 14px;
}

@media (max-width: 820px) {
    .project .project-title {
        font-size: 26px;
    }
}

@media (max-width: 480px) {
    .project .project-title {
        font-size: 24px;
    }

    .project-card {
        width: 80%;
    }
}