.skills {
    display: flex;
    justify-content: center;
    height: 100vh;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)), url('../images/Skills.png');
    background-size: fill;
    background-position: left top;
}

.skills .skills-width {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1240px;
}

.skills .skills-width .skills-title {
    font-size: 28px;
    color: var(--text-clr);
    margin-bottom: 40px;
}

.skills .skills-width .skills-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.skills .skills-width .skills-content .skills-item {
    background-color: var(--iconbox-clr);
    border-radius: 100px;
    margin: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.skills .skills-width .skills-content .skills-item:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
    background-color: rgba(255, 255, 255, 0.1); 
}

.skills .skills-width .skills-content .skills-item img {
    width: 125px;
    height: 125px;
    margin: 20px;
    transition: transform 0.3s ease; 
}

.skills .skills-width .skills-content .skills-item:hover img {
    transform: rotate(10deg) scale(1.1); 
}

@media (max-width: 820px) {
    .skills .skills-width .skills-title {
        font-size: 26px;
    }

    .skills .skills-width .skills-content .skills-item {
        margin: 30px 30px;
    }

    .skills .skills-width .skills-content .skills-item img {
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 480px) {
    .skills {
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .skills .skills-width .skills-title {
        font-size: 24px;
    }

    .skills .skills-width .skills-content .skills-item {
        margin: 20px 15px;
    }

    .skills .skills-width .skills-content .skills-item img {
        width: 60px;
        height: 60px;
    }
}