.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: gray;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../images/contact.jpg');
  background-size: cover;
  background-position: center;
}

.contact .contact-width {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1240px;
  width: 100%;
  gap: 20px;
}

.contact .contact-section {
  display: flex;
  width: 100%;
  min-height: 100%;
  opacity: 0; /* Initially hidden */
  transform: translateX(-100px); /* Start from the left for the left column */
  animation: slideIn 1s forwards;
}

.about-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 calc(50% - 40px);
  background: rgba(75, 75, 75, 0.6);
  border-radius: 10px;
  padding: 20px 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: 0 30px;
  border: 1px solid #000080;
  min-height: 60%;
  opacity: 0; /* Initially hidden */
  transform: translateX(-100px); /* Start from the left */
  animation: slideInLeft 1s forwards;
  animation-delay: 0.5s; /* Delay for staggered effect */
}

.contact-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 calc(50% - 40px);
  background: rgba(75, 75, 75, 0.6);
  border-radius: 10px;
  padding: 20px 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: 0 30px;
  border: 1px solid #000080;
  min-height: 60%;
  opacity: 0; /* Initially hidden */
  transform: translateX(100px); /* Start from the right */
  animation: slideInRight 1s forwards;
  animation-delay: 0.5s; /* Delay for staggered effect */
}

/* Slide-in from left for the about-column */
@keyframes slideInLeft {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Slide-in from right for the contact-column */
@keyframes slideInRight {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideIn {
  100% {
    opacity: 1;
    transform: translateX(0); /* Slide from -100px to 0 */
  }
}

.contact-section h3 {
  font-size: 22px;
  color: #FFFFFF;
  margin-bottom: 0px;
  text-align: center;
}

.contact-section p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 10px;
  text-align: center;
}

.social-icons img {
  width: 40px;
  height: 40px;
  margin: 10px;
}

.contact-form {
  width: 100%;
  margin-top: 20px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.contact-form .form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.contact-form .form-group label {
  font-size: 16px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
}

.contact-form .form-group input,
.contact-form .form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.7);
  color: black;
  border: 1px solid #000080;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.contact-form .form-group input:focus,
.contact-form .form-group textarea:focus {
  border-color: #007bff;
}

.contact-form .form-group textarea {
  height: 120px;
  resize: none;
}

.contact-form button {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  background-color: #000080;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #003366;
}

@media (max-width: 820px) {
  .contact {
    padding: 20px 0;
  }

  .contact .contact-section {
    display: block;
    width: 100%;
    text-align: center;
  }

  .about-column, .contact-column {
    width: 80%;
    margin: 0 auto 20px;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .contact {
    padding: 50px 0;
  }

  .about-column, .contact-column {
    width: 75%;
  }
}