.header {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.header-width {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-clr);
    width: 90%;
    max-width: 1240px;
    padding: 10px 0;
}

.header .nav {
    display: flex;
    align-items: center;
}

.header .nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.header .nav li {
    display: inline;
    margin: 0 15px;
    transition: transform 0.3s ease;
}

.header .title  a {
    color: var(--text-clr);
    text-decoration: none;
    transition: transform 0.3s ease;
}

.header .nav li a {
    color: var(--text-clr);
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    transition: transform 0.3s ease;
}

.header .nav li a:hover {
    font-size: 23px; 
}

.header .title a:hover h1 {
    transform: scale(1.25) rotate(-15deg); 
}

@media (max-width: 820px) {
    .header-width {
        flex-direction: row;
        align-items: center;
    }

    .header .nav li {
        margin: 0 10px;
    }

    .header .nav li a {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .header-width {
        flex-direction: row;
        align-items: center;
    }

    .header .nav ul {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .header .nav li a {
        font-size: 16px;
    }
}