:root {
  --text-clr: #FFFFFF;
  --text2-clr: #dfefff;
  --text-highlight-clr: #6F42C1;
  --sec-clr: #E9ECEF;
  --sec2-clr: #F0F4FF;
  --btn-clr: #343A40;
  --btn2-clr: #17A2B8;
  --border-clr: #DEE2E6;
  --box-shadow-clr: rgba(0, 0, 0, 0.1);
  --iconbox-clr: #dfefff;
  --text-dark-clr: #333333;
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Roboto", serif;
  color: var(--text-clr);
}

/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Customize the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Customize the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

/* Optional: Hover effect for the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(173, 216, 230, 0.7);
}