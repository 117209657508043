.banner {
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url('../images/banner2.jpg');
    min-height: 100vh;
    background-size: cover;
    background-position: bottom left;
    overflow: hidden; /* Ensure that elements do not overflow the container */
}

.banner-width {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1240px;
}

.banner .banner-width .banner-title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 50px;
    opacity: 0; /* Initially hidden */
    animation: fadeIn 1s forwards; /* Apply fade-in animation */
}

/* Slide-in effect for h2 */
.banner .banner-width .banner-title h2 {
    font-size: 50px;
    font-weight: 700;
    color: var(--text-clr);
    transform: translateX(100vw); /* Start off-screen to the right */
    animation: slideInFromRight 1s forwards 0.3s; /* Slide in from the right */
}

/* Slide-in effect for h3 */
.banner .banner-width .banner-title h3 {
    font-size: 25px;
    font-weight: 500;
    color: var(--text2-clr);
    margin-top: -20px;
    transform: translateX(100vw); /* Start off-screen to the right */
    animation: slideInFromRight 1s forwards 0.6s; /* Slide in from the right */
}

@keyframes slideInFromRight {
    to {
        transform: translateX(0); /* Move to original position */
    }
}

@keyframes fadeIn {
    to {
        opacity: 1; /* Fade in the entire banner */
    }
}

@media (max-width: 820px) {
    .banner {
        background-position: 25% bottom;
    }

    .banner-width {
        width: 90%;
    }

    .banner .banner-width .banner-title h3 {
        margin-top: -20px;
    }
}

@media (max-width: 480px) {
    .banner {
        background-position: 30% bottom;
    }

    .banner .banner-width .banner-title {
        padding: 20px;
    }
}